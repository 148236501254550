import {
  createContext, useCallback, useContext, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';

export const SnackbarContext = createContext();

export function SnackbarProvider(props) {
  const { children } = props;
  const [snackbarOptions, setSnackbarOptions] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const showSnackbar = useCallback((options) => {
    setSnackbarOptions(options);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const showSnackbarValue = useMemo(() => ({ showSnackbar }), [showSnackbar]);

  return (
    <SnackbarContext.Provider value={showSnackbarValue}>
      {children}
      <Snackbar
        key={`${snackbarOptions.message}|${snackbarOptions.variant}`}
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <div>
          <GlooSnackbarContent
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>,
            ]}
            {...snackbarOptions}
          />
        </div>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSnackbar = () => useContext(SnackbarContext);

function GlooSnackbarContent(props) {
  const { variant, ...rest } = props;

  const theme = useTheme();
  const styles = css`
    &.success {
      background-color: ${theme.palette.gloo.springGreen.main};
      color: ${theme.palette.gloo.springGreen.contrastText};
    }
    &.error {
      background-color: ${theme.palette.gloo.orange.main};
      color: ${theme.palette.gloo.orange.contrastText};
    }
    &.warning {
      background-color: ${theme.palette.gloo.goldenYellow.main};
      color: ${theme.palette.gloo.goldenYellow.contrastText};
    }
    &.info {
      background-color: ${theme.palette.gloo.skyBlue.main};
      color: ${theme.palette.gloo.skyBlue.contrastText};
    }
  `;

  return (
    <SnackbarContent css={styles} classes={{ root: variant }} {...rest} />
  );
}

GlooSnackbarContent.propTypes = {
  variant: PropTypes.string,
};

GlooSnackbarContent.defaultProps = {
  variant: 'info',
};
