/* eslint-disable react/prop-types */
import '@styles/tailwind.css';
import '@styles/typography.css';
import React, { useEffect } from 'react';
import Head from 'next/head';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { ThemeProvider as EmotionThemeProvider, Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from '@lib/theme';
import { ApolloProvider } from '@apollo/client';
import { initializeApolloClient } from '@lib/apollo-client';
import { useRouter } from 'next/router';
import { getCookies, COOKIES } from '@lib/cookies';
import TagManager from 'react-gtm-module';
import Layout from '@components/Layout';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { rollbarClientConfig } from '@lib/rollbar';
import { SnackbarProvider } from '@components/material-ui/Snackbar';

const MUI_THEME = createTheme(theme, {
  palette: {
    primary: {
      main: '#1A9BC7', // Cerulean
    },
    secondary: {
      main: '#336785', // Storm Blue
    },
  },
});

const gtmId = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

export default function App({ Component, pageProps }) {
  const router = useRouter();

  // Google Tag Manager
  useEffect(() => {
    if (gtmId) TagManager.initialize({ gtmId });
  }, []);

  // Set cookies
  useEffect(() => {
    const ut = router?.query?.ut;

    if (ut) {
      const cookies = getCookies();
      cookies.set(COOKIES.UT, ut, { sameSite: 'lax' });
    }
  }, [router]);

  return (
    <>
      <Head>
        <title>Gloo - Assessments Results</title>
        {/* Use minimum-scale=1 to enable GPU rasterization */}
        <meta
          name="viewport"
          content={
          'user-scalable=0, initial-scale=1, '
          + 'minimum-scale=1, width=device-width, height=device-height'
        }
        />
      </Head>
      <RollbarProvider config={rollbarClientConfig}>
        <ErrorBoundary>
          <ApolloProvider client={initializeApolloClient()}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={MUI_THEME}>
                <EmotionThemeProvider theme={MUI_THEME}>
                  <SnackbarProvider>
                    <Global
                      styles={{
                        html: {
                          fontSize: '62.5%',
                        },
                        a: {
                          color: theme.palette.primary.main,
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        },

                        /* Hide OneTrust floating button for Cookie Settings */
                        '#ot-sdk-btn-floating': {
                          display: 'none !important',
                        },
                      }}
                    />
                    <CssBaseline />
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  </SnackbarProvider>
                </EmotionThemeProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </RollbarProvider>
    </>
  );
}
