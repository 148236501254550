import React from 'react';
import PropTypes from 'prop-types';

function Container({ children, ...rest }) {
  return <div className="max-w-[1240px] mx-auto" {...rest}>{children}</div>;
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
