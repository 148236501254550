import { setContext } from '@apollo/client/link/context';
import { getCookies, COOKIES } from '../cookies';
import rollbar from '../rollbar';

export default function createAuthLink() {
  return setContext((request, { headers }) => {
    const queryString = typeof window !== 'undefined' && window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cookies = getCookies(request);

    const urlUserToken = urlParams.get(COOKIES.UT.toLocaleLowerCase());
    const cookieUserToken = cookies.get(COOKIES.UT);

    let finalUserToken;

    if (urlUserToken !== null) {
      if (cookieUserToken === undefined || cookieUserToken !== urlUserToken) {
        // If the user token cookie hasn't been set, or if a user is retaking
        // an assessment or taking a second assessment (which causes them to
        // have an invalid user token cookie), the urlUserToken
        // is used as the final source of truth.
        cookies.set(COOKIES.UT, urlUserToken, { sameSite: 'lax' });
      }
      finalUserToken = urlUserToken;
    } else {
      finalUserToken = cookieUserToken;
    }

    const newHeaders = { ...headers };

    if (finalUserToken) {
      newHeaders['X-Silicone-User-Token'] = finalUserToken;
    } else if (typeof window !== 'undefined') {
      rollbar.info('No ut found in the user browser. See if there is a 401 corelate with this');
    }

    // return the headers to the context so httpLink can read them
    return { headers: newHeaders };
  });
}
