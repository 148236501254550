import { setContext } from '@apollo/client/link/context';

const { v4: uuidv4 } = require('uuid');

export default function createRequestIdLink() {
  return setContext((request, { headers }) => {
    const newHeaders = { ...headers, 'X-Client-Request-Id': uuidv4() };

    // return the headers to the context so httpLink can read them
    return { headers: newHeaders };
  });
}
