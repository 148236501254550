const body1 = {
  fontFamily: '"Open Sans", sans-serif',
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  letterSpacing: 0,
};

const springGreen = {
  main: '#41B768',
  contrastText: '#FFF',
};
const stormBlue = {
  main: '#336785',
  contrastText: '#FFF',
};
const charcoal = {
  main: '#262524',
  contrastText: '#FFF',
};
const mistyGray = {
  main: '#ebeced',
  contrastText: '#000',
};
const goldenYellow = {
  main: '#faa61a',
  contrastText: '#FFF',
};
const orange = {
  main: '#f05A23',
  contrastText: '#FFF',
};
const skyBlue = {
  main: '#43bee6',
  contrastText: '#FFF',
};
const rougePink = {
  main: '#ee558c',
  contrastText: '#FFF',
};

const greys = {
  0: '#fafafa',
  50: '#EBEAEA',
  100: '#CFCECE', // used for disabled states
  200: '#B9B9B9',
  300: '#A4A4A3', // used for inactive states
  400: '#8F8F8E',
  500: '#7B7A7A',
  600: '#656463',
  700: '#50504F',
  800: '#3A3938',
  900: '#262524', // used for active states
};

export const theme = {
  spacing: (factor) => `${0.8 * factor}rem`,
  typography: {
    htmlFontSize: 10, // Paired with the html 62.5% font-size specified in withMaterialUI
    h1: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '3.9rem',
      lineHeight: '4.4rem',
      letterSpacing: 0,
    },
    h2: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '3.1rem',
      lineHeight: '3.6rem',
      letterSpacing: 0,
    },
    h3: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '2.5rem',
      lineHeight: '3rem',
    },
    h4: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
    h5: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.6rem',
      lineHeight: '2.1rem',
    },
    h6: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.3rem',
      lineHeight: '1.8rem',
    },
    subtitle1: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '1.6rem',
      fontWeight: 300,
      lineHeight: '2.1rem',
    },
    subtitle2: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '1.4rem',
      fontWeight: 300,
      lineHeight: '1.8rem',
    },
    body1,
    body2: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '1.3rem',
      lineHeight: '2rem',
      letterSpacing: 0,
    },
    button: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '1.6rem',
      lineHeight: '2.1rem',
      textTransform: 'none',
      fontWeight: 600,
    },
    caption: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '1.3rem',
      lineHeight: '1.8rem',
      letterSpacing: 0.5,
    },
    overline: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '1.3rem',
      lineHeight: '1.8rem',
      letterSpacing: 2,
      opacity: 0.6,
    },
  },
  palette: {
    primary: {
      ...stormBlue,
    },
    secondary: {
      ...skyBlue,
    },
    error: {
      ...orange,
    },
    text: {
      primary: greys[900],
      secondary: greys[700],
    },
    gloo: {
      springGreen,
      stormBlue,
      charcoal,
      mistyGray,
      goldenYellow,
      orange,
      skyBlue,
      rougePink,
    },
    grey: greys,
    background: {
      default: '#fff',
      secondary: greys[0],
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '2rem',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          color: greys[500],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          cursor: 'pointer',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: '"Montserrat", sans-serif',
          color: 'inherit',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          color: stormBlue.main,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '1.3rem',
        },
        text: {
          padding: '1.3rem',
        },
        outlined: {
          padding: '1.3rem',
        },
        sizeSmall: {
          padding: '.6rem',
        },
        sizeLarge: {
          padding: '2.1rem',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          ...body1,
        },
      },
    },
  },
};
