import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import createErrorLink from './createErrorLink';
import createAuthLink from './createAuthLink';
import createFetchLink from './createFetchLink';
import createRequestIdLink from './createRequestIdLink';

const isOnServer = typeof window === 'undefined';

let apolloClient;

function createApolloClient() {
  const cache = new InMemoryCache();

  const authLink = createAuthLink();
  const errorLink = createErrorLink();
  const fetchLink = createFetchLink();
  const requestIdLink = createRequestIdLink();

  return new ApolloClient({
    connectToDevTools: !isOnServer,
    ssrMode: isOnServer,
    link: ApolloLink.from([
      errorLink,
      authLink,
      requestIdLink,
      fetchLink,
    ]),
    cache,
  });
}

export function initializeApolloClient() {
  // Always return a new Apollo Client for SSR or SSG
  if (isOnServer) return createApolloClient();

  // Create Apollo Client once for the client
  if (!apolloClient) apolloClient = createApolloClient();

  return apolloClient;
}
