import { HttpLink } from '@apollo/client';

export default function createFetchLink() {
  const options = {
    uri: `${process.env.NEXT_PUBLIC_ASSESSMENTS_RESULTS_WEB_HOST || ''}/graphql`,
    credentials: 'same-origin',
  };

  return new HttpLink(options);
}
