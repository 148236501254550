import Rollbar from 'rollbar';

const defaultLevel = 'warning';
const defaultEnvironment = 'production';

const _getConfig = (apiKey) => ({
  accessToken: apiKey,
  enabled: !!apiKey,
  captureUncaught: true,
  captureUnhandledRejections: true,
  reportLevel: (process.env.NEXT_PUBLIC_WINSTON_LOG_LEVEL ?? defaultLevel),
  payload: {
    environment: (process.env.NEXT_PUBLIC_ROLLBAR_ENV ?? defaultEnvironment),
  },
});

export const rollbarClientConfig = _getConfig(process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_API_KEY);

function createRollbarInstance(config = {}) {
  if (typeof window !== 'undefined') {
    return new Rollbar(rollbarClientConfig);
  }

  return new Rollbar(_getConfig(config.serverApiKey));
}

const rollbar = createRollbarInstance({
  serverApiKey: process.env.ROLLBAR_SERVER_API_KEY,
});

export default rollbar;
