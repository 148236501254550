/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@components/Container';

function Layout({
  children,
}) {
  return (
    <>
      {children}
      <Container>
        <div className="my-4 text-center">
          <a style={{ fontFamily: 'Lato, sans-serif' }} className="cursor-pointer text-[#3f51b5] hover:underline" href="https://www.gloo.us/legal/privacy-statement" target="_blank" rel="noopener noreferrer">
            Privacy Statement
          </a>
          &nbsp;|&nbsp;
          <a style={{ fontFamily: 'Lato, sans-serif' }} className="cursor-pointer text-[#3f51b5] hover:underline" onClick={() => { if (window.OneTrust) { window.OneTrust.ToggleInfoDisplay(); } }}>
            Cookie Settings
          </a>
        </div>
      </Container>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
