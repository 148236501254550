import _get from 'lodash/get';
import { onError } from '@apollo/client/link/error';
import rollbar from '../rollbar';

export default function createErrorLink() {
  return onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        const errorMessage = `[GraphQL error]: ${message}`;
        // eslint-disable-next-line no-console
        console.error(errorMessage);
        rollbar.error(errorMessage, {
          customData: {
            locations,
            path,
            query: _get(operation, 'query.loc.source.body'),
          },
        });
      });
    }

    if (networkError) {
      rollbar.error(networkError);
    }
  });
}
